import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Actions, actions } from 'actions/recordingActions';
import { AxiosRequestConfig } from 'axios';
import intl from 'react-intl-universal';
import { Dispatch } from 'redux';
import { buildURL, simpleAPICall } from './ApiHelper';

const getAxiosConfig = (action: PayloadAction<string, string>): AxiosRequestConfig => {
  return {
    method: 'get',
    responseType: 'json',
    data: action.payload,
    validateStatus: (status) => {
      // Reject only if the status code is greater than or equal to 400
      return status < 400;
    }
  };
};

const parseError = (err: any) => {
  let errorResponse = {
    message: null,
    status: null,
    statusText: null
  };

  if (!!err) {
    if ((typeof err) === 'object') {
      errorResponse.message = (err.message) ? err.message : null;
      errorResponse.status = (err.response) ? err.response.status : null;
      errorResponse.statusText = (err.response) ? err.response.statusText : null;
      return errorResponse
    }
    errorResponse.message = err.toSting();
    return errorResponse;
  }
  return JSON.stringify(errorResponse);
};

export const getVCRecordingMiddleware =
  () =>

    (next: Dispatch) => {

      return (action: PayloadAction<string, string>) => {
        if (action.type !== Actions.FETCH_RECORDING_START) {
          return next(action);
        }

        const id = action.payload['id'];
        const token = action.payload['token'];
        if (!!id || !!token) {

          const url = buildURL(action.type, action.payload['id'], action.payload['token']);
          return simpleAPICall(url, getAxiosConfig(action)).then((res: object) => {
            const responseBody = (res) ? res['data'] : undefined;
            if (responseBody && responseBody.error) {
              // tslint:disable-next-line
              console.log(parseError(responseBody.error));
              return next(actions.fetchRecordingFailure({ error: intl.get('unspecified') }));
            }
            return next(actions.fetchRecordingSuccess(responseBody));
          })
            .catch((error: any) => {
              // tslint:disable-next-line
              console.log(parseError(error));
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                return next(actions.fetchRecordingFailure({ error: intl.get('networkError') }));
              }
              // Either bad token or bad recording id
              return next(actions.fetchRecordingFailure({ error: intl.get('noAccess') }));
            });

        }
        // Id or token missing from URL
        return next(actions.fetchRecordingInvalidUrl({ error: intl.get('invalidUrl') }));
      }
    };
