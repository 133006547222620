export enum Actions {
  CHANGE_LOCALE = 'CHANGE_LOCALE'
}

/**
 * There are many language codes, but this enum is what is supported
 */
export enum SupportedLanguage {
  cs = 'cs',
  de = 'de',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  it = 'it',
  ko = 'ko',
  ja = 'ja',
  nl = 'nl',
  no = 'no',
  pl = 'pl',
  pt = 'pt',
  sv = 'sv',
  zh = 'zh'
}

export const languageDataProvider: Map<Array<string>,string> = new Map([
  [[SupportedLanguage.cs], 'Čeština'],
  [[SupportedLanguage.de], 'Deutsch'],
  [[SupportedLanguage.en], 'English'],
  [[SupportedLanguage.es], 'Español'],
  [[SupportedLanguage.fr], 'Français'],
  [[SupportedLanguage.it], 'Italiano'],
  [[SupportedLanguage.nl], 'Nederlands'],
  [[SupportedLanguage.no], 'Norsk'],
  [[SupportedLanguage.pl], 'Polski'],
  [[SupportedLanguage.pt], 'Português-Brasil'],
  [[SupportedLanguage.fi], 'Suomi'],
  [[SupportedLanguage.sv], 'Svenska'],
  [[SupportedLanguage.zh], '简体中文'],
  [[SupportedLanguage.ja], '日本語'],
  [[SupportedLanguage.ko], '한국어']
]);



export const DEFAULT_LOCALE: string = 'en';
