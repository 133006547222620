import React from 'react';
import intl from 'react-intl-universal';
import styles from './Footer.module.scss';

export const FooterComponent = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.row}>
        <span>
          {`© ${new Date().getFullYear()} Lifesize, Inc. ${intl.get('rights')}`}
        </span>
        <a
          href={`https://www.lifesize.com/${intl.getInitOptions().currentLocale}/company/legal-notices/privacy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.get('privacyPolicy')}
        </a>
        |
        <a href="https://www.lifesize.com/en/company/legal-notices/terms-of-service"
           target="_blank"
           rel="noopener noreferrer"
        >
          {intl.get('TOS')}
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
