import { DEFAULT_LOCALE, SupportedLanguage } from '../constants/languageConstants';

/**
 * Returns the two char language code for a browser preference
 * Could be in ISO format ('en-US' ),
 * so returns the language ('en'), and not locale part ('US').
 * Returns null if no match found.
 */
const getSupportedLanguageCode = (code: string) => {
  if (!!code) {
    // Lang may come back in 'en-US' ISO format.
    const langCode = code.substring(0, 2);
    // Check that the code is supported
    if (!!SupportedLanguage[langCode]) {
      return langCode;
    }
  }
  return null;
};

/**
 * Returns true if two-char language code is supported
 */
const isSupportedLanguageCode = (code: string) => {
  if (!!code) {
    return !!getSupportedLanguageCode(code);
  }
  return false;
};

const getLanguageCodeInitialState = (code: string) => {
  if (!!code) {
    return getSupportedLanguageCode(code) || DEFAULT_LOCALE;
  }
  return DEFAULT_LOCALE;
};

/**
 * Browsers like Chrome allow multiple entries for browser language,
 * ranked in order by priority. This function will attempt to get that value,
 * or just return the DEFAULT_LOCALE (en), if not found;
 */
const getFirstBrowserLanguage = () => {
  try {
    let nav = window.navigator, i, language;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (!!language) {
          if (isSupportedLanguageCode(language)) {
            return getLanguageCodeInitialState(language);
          }
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (!!language) {
        if (isSupportedLanguageCode(language)) {
          return getLanguageCodeInitialState(language);
        }
      }
    }
  } catch (e) {
    return DEFAULT_LOCALE;
  }
  return DEFAULT_LOCALE;
};

export {
  getFirstBrowserLanguage,
  isSupportedLanguageCode
};
