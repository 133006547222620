import axios, { AxiosRequestConfig } from 'axios';

export const apiDomain = process.env.REACT_APP_VC_API_DOMAIN;
const getPublicRecordingInfoPath = `${apiDomain}/api/v1/cloud-api/cloudpublicvideo`;

export const buildURL = (ActionType: string, requestedId: string, access_token: string) => {
  return `${getPublicRecordingInfoPath}/${requestedId}?publictoken=${access_token}`;
};

export const simpleAPICall = async (url: string, config: AxiosRequestConfig) => {
  return await axios(url, config);
};
