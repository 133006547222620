import { createSelector } from 'reselect';
import RecordingState from 'state/Recording';
import { RootState } from '../state/Root';

/**
 * Direct selector to the Pairing state domain
 */
const selectRecordingInfoState = (state: RootState) => state.recording;

export const selectRecordingInfo = createSelector(
  selectRecordingInfoState,
  (recording: RecordingState) => {
    return recording;
  }
);
