/**
 * Combine all middleware in this file and export the combined middleware.
 */
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { getVCRecordingMiddleware } from './middleware/getVCRecordingMiddleware';

// const env = process.env.NODE_ENV;

export const enableLogging = !!window.localStorage.getItem('reduxLogging') ||
  (process.env.REACT_APP_IS_PRODUCTION !== 'true' && process.env.NODE_ENV !== 'test');

export default function combineMiddleware() {
  const filteredLogger = createLogger({
    predicate: () => enableLogging,
  });

  return applyMiddleware(...[thunk, getVCRecordingMiddleware, filteredLogger]);
}
