import moment from 'moment';
import intl from 'react-intl-universal';

/**
 * Returns string in '4/12/2018 at 12:36 PM' format (notice the 'at')
 */
export const formatDateAtTimeFromUnix = (unixTime: number): string => {
  if (unixTime) {
    const momentDateObj = moment(unixTime).toDate();
    const date = intl.get('doNotTranslateDate', { dateObj: momentDateObj });
    const dateToday = intl.get('doNotTranslateDate', { dateObj: moment().toDate() });
    const time = intl.get('doNotTranslateTimeShort', { timeObj: momentDateObj });
    return (dateToday === date
        ? intl.get('todayAtTime', { time: time })
        : intl.get('dateAtTime', { date: date, time: time })
    );
  }
  return '';
};
