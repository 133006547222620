import { connect } from 'react-redux';
import Header from '../components/Header/Header';
import { selectCurrentLocale } from '../selectors/languageSelector';
import { RootState } from '../state/Root';

const mapStateToProps = (state: RootState) => {
  const languageInfo = selectCurrentLocale(state);
  return {countryCode: languageInfo};
};

export default connect(mapStateToProps)(Header);
