import { Record } from 'immutable';
import { getPublicAccessToken, getRequestedRecordingId } from '../utils/query-utils';
import { IRecordingFetchState } from './interfaces/IRecordingFetchState';

const initialState: IRecordingFetchState = {
  accessToken: getPublicAccessToken(),
  requestedId: getRequestedRecordingId(),
  data: {
    id: "",
    date_created: 0,
    description: "",
    iframe: "",
    name: ""
  },
  error: null

};

export default class Recording extends Record(initialState) {
}
