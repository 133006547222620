import { Record } from 'immutable';
import LanguageState from '../state/Language';
import RecordingsState from './Recording';

export interface RootState {
  language: LanguageState;
  recording: RecordingsState
}

const initialState: RootState = {
  language: new LanguageState(),
  recording: new RecordingsState()
};

export class Root extends Record(initialState) {}

