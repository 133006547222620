import classnames from 'classnames';
import React from 'react';
import * as styles from './Icon.scss';

/*
 * this component requires that the global styles 'icon' along with the icon-class passed in, exist
 * be sure to import the IcoMoon generated css or scss globally
 */

// props passed in
export interface OwnProps {
  classes: string[];
}

class Icon extends React.PureComponent<OwnProps> {
  render(): React.ReactNode {
    const { classes } = this.props;
    const classNames = classnames(
      styles.container,
      'icon',
      classes
    );

    return (
      <i className={classNames}/>
    );
  }
}

export default Icon;
