import Cookies from 'universal-cookie';

const COOKIE_NAME = 'recording-lang';
const cookies = new Cookies();

const getLanguageCookie = () => {
  const cookie = cookies.get(COOKIE_NAME);
  return !!cookie ? cookie.substring(0, 2) : null;
};

const setLanguageCookie = (value) => {
  cookies.set(COOKIE_NAME, value, {
    domain: window.location.hostname.substring(window.location.hostname.lastIndexOf(".", window.location.hostname.lastIndexOf(".") - 1) + 1),
    path: '/',
  });
};


export {
  getLanguageCookie,
  setLanguageCookie
}
