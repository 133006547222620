import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { languageDataProvider } from '../../constants/languageConstants';
import { setLanguageCookie } from '../../utils/cookie-utils';
import styles from './LanguageDropdown.module.scss';

interface Props {
  countryCode: string,
  languageProvider: Array<string>
}

interface State {
  isExpanded: boolean;
}

class LanguageDropdown extends React.Component<Props, State> {
  readonly state = { isExpanded: false };

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  handleClickOutside() {
    this.setState({
      isExpanded: false,
    });
  }

  handleSelectLanguage(code: any) {
    setLanguageCookie(code);
    this.setState({
      isExpanded: false,
    });
    window.location.reload();
  }

  createLi = (languageDataProvider: Map<Array<string>, string>) => {
    let childMenuItems: Array<JSX.Element> = [];
    languageDataProvider.forEach((item, value) => {
      childMenuItems.push(<li key={value[0]}>
        <button
          onClick={() => this.handleSelectLanguage(value[0])}
          title={item}>
          {item}
        </button>
      </li>);
    });
    return childMenuItems;
  };

  render() {
    const { countryCode, languageProvider } = this.props;
    const { isExpanded } = this.state;

    return <div className={styles.container}>
      <button
        onClick={(event) => {
          this.handleToggle();
          event.preventDefault();
        }}
      >
        {languageProvider[countryCode]}
        <div className={styles.arrowDown}/>
      </button>
      {
        (isExpanded) ?
          <div className={styles.dropdown}>
            <ul>
              {
                this.createLi(languageDataProvider)
              }
            </ul>
          </div> :
          null
      }
    </div>;
  }
}

export default enhanceWithClickOutside(LanguageDropdown);
