import { Record } from 'immutable';
import { getFirstBrowserLanguage } from 'utils/language-utils';
import { getLocaleCodeFromUrl } from 'utils/query-utils';
import { getLanguageCookie } from '../utils/cookie-utils';

export interface LanguageState {
  locale: string;
}

const initialState: LanguageState = {
  locale: getLanguageCookie() || getLocaleCodeFromUrl() || getFirstBrowserLanguage()
};

export default class Language extends Record(initialState) {
}
