import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Actions } from '../constants/languageConstants';
import Language from '../state/Language';

const languageProviderReducer =
  (state = new Language(), action: PayloadAction<Actions, string>) => {
    if (action.type === Actions.CHANGE_LOCALE) {
      return state.set('locale', action.payload);
    } else {
      return state;
    }
  };

export default languageProviderReducer;
