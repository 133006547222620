import { isSupportedLanguageCode } from './language-utils';

const PUBLIC_VIDEO = 'publicvideo';
const QUERY_START = '?';

const isHashUrl = () => {
  return window.location.hash ? window.location.hash.indexOf('#') > -1 : false;
};

/**
 * Parse URL for query strings
 */
const getAllQueryParams = () => {
  let queryDict = {};
  let paramsToIterate = '';
  if (isHashUrl()) {
    // Use window.location.hash
    const queryParamsOnHash = window.location.hash.split(QUERY_START);
    if (queryParamsOnHash.length === 2) {
      paramsToIterate = queryParamsOnHash[1].substr(0);
    }
  } else {
    // Use window.location.search
    paramsToIterate = window.location.search.substr(1);
  }

  paramsToIterate.split('&').forEach(function (item) {
    queryDict[item.split('=')[0]] = item.split('=')[1]
  });

  return queryDict;
};

/**
 * Get a specific query params
 * @param key
 */
const getQueryStringValueByKey = (key: string) => {
  try {
    let queryDict = getAllQueryParams();
    return queryDict[key] || null;
  } catch (e) {
    return null;
  }
};

/**
 * Get the recording id part of URL
 * Supports # or regular URL
 * Requires /publicvideo preceding the recording id
 * Returns null if no match found
 *
 * URLS can be in the following formats:
 * https://<hostname>/publicvideo/<recording_id>?vcpubtoken=<token>
 * And the older Angular route format:
 * https://<hostname>/#/publicvideo/<recording_id>?vcpubtoken=<token>
 */
const getRequestedRecordingId = () => {
  let urlSections;
  let indexOfPublicVideo = -1;

  if (isHashUrl()) {
    urlSections = window.location.hash.split('/');
    indexOfPublicVideo = window.location.hash.indexOf(PUBLIC_VIDEO);
    if (indexOfPublicVideo > -1) {
      return urlSections[2].split(QUERY_START)[0];
    }
  } else {
    urlSections = window.location.pathname.split('/');
    indexOfPublicVideo = window.location.pathname.indexOf(PUBLIC_VIDEO);
    if (indexOfPublicVideo > -1) {
      return urlSections[2];
    }
  }
  return null;
};

/**
 * Attempt to get a valid locale code from url.
 * Return null otherwise.
 * (Note: It's easier to use URLSearchParams, but not supported by IE11
 * Vanilla javascript to get query params)
 */
const getLocaleCodeFromUrl = () => {
  try {
    const locale = getQueryStringValueByKey('lang');
    if (!!locale && isSupportedLanguageCode(locale)) {
      return locale;
    }
  } catch (e) {
    return null;
  }
  return null;
};

const getPublicAccessToken = () => {
  try {
    return getQueryStringValueByKey('vcpubtoken');
  } catch (e) {
    return null;
  }
};

export {
  getLocaleCodeFromUrl,
  getRequestedRecordingId,
  getPublicAccessToken
}
