import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectRecordingInfo } from 'selectors/recordingInfoSelector';
import actions from '../actions/recordingActions';
import { MainLayout } from '../components/MainLayout/MainLayout';
import { IRecordingFetchState } from '../state/interfaces/IRecordingFetchState';
import { RootState } from '../state/Root';

const mapStateToProps = (state: RootState) => {
  const recordingInfo: IRecordingFetchState = selectRecordingInfo(state);
  return {
    data: recordingInfo.data,
    error: recordingInfo.error,
    requestedId: recordingInfo.requestedId,
    accessToken: recordingInfo.accessToken
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRecordingInfo: (requestedId: string, accessToken: string) => {
    dispatch(actions.fetchRecording(requestedId, accessToken));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
