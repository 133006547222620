import Header from 'containers/HeaderContainer';
import React from 'react';
import intl from 'react-intl-universal';
import { IRecordingFetchState } from '../../state/interfaces/IRecordingFetchState';
import ErrorPanel from '../ErrorPanel/ErrorPanel';
import Footer from '../Footer/Footer';
import { RecordingPanel } from '../RecordingPanel/RecordingPanel';
import styles from './MainLayout.module.scss';

interface Props extends IRecordingFetchState {
  fetchRecordingInfo: (requestedId: string, accessToken: string) => void;
}

export class MainLayout extends React.Component<Props> {

  componentDidMount() {
    const { requestedId, accessToken } = this.props;
    this.props.fetchRecordingInfo(requestedId || '', accessToken || '');
  }

  render() {
    const { accessToken, error, data } = this.props;

    return (
      <div className={styles.container}>
        {/* App Header */}
        <Header />
        {/* Main Content */}
        {
          !accessToken || error ?
            <ErrorPanel message={error || intl.get('noAccess')} /> :
            <RecordingPanel data={data} />
        }
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
