import React from 'react';
import intl from 'react-intl-universal';
import Icon from '../Common/Icon';
import styles from './ErrorPanel.module.scss';

interface Props {
  message?: string | null;
}

const ErrorPanel: React.FunctionComponent<Props> = (props) => {

  const { message } = props;

  return (
    <div className={styles.container}>
      <div className={styles.backgroundFrame}>
        <Icon classes={['icon-record', styles.icon]}/>
        <div className={styles.message}>
          { !!message ? message :  intl.get('noAccess')}</div>
      </div>
    </div>

  );
};

export default ErrorPanel;
