import React from 'react';
import { IRecordingDataItem } from '../../state/interfaces/IRecordingDataItem';
import { formatDateAtTimeFromUnix } from '../../utils/date-time-utils';
import { IFramePlaceHolder } from '../IFramePlaceholder/IFramePlaceholder';
import styles from './RecordingPanel.module.scss';

interface Props {
  data: IRecordingDataItem
}

interface State {
  isIFrameLoaded: boolean;
}

export class RecordingPanel extends React.Component<Props, State> {
  readonly state = { isIFrameLoaded: false };

  onIFrameLoaded = () => {
    this.setState({ isIFrameLoaded: true })
  };

  render() {
    const { data } = this.props;
    const { isIFrameLoaded } = this.state;
    return (
      <div className={styles.container}>

        <div className={styles.hgBody}>

          <div className={styles.stretchWrapper}>
            <iframe
              className={isIFrameLoaded ? '' : styles.hidden}
              id="iframe"
              allowFullScreen={true}
              onLoad={this.onIFrameLoaded}
              title={"Video Container"}
              src={data.iframe}
              height="100%"
              width="100%"
            />
            <span className={isIFrameLoaded ? styles.hidden : ''}>
              <IFramePlaceHolder/>
            </span>
          </div>

          
        </div>
        <div className={styles.meta}>
          <div className={styles.title}>{data.name}</div>
          <div className={styles.description}>{data.description}</div>
          <div className={styles.timeAndViewsContainer}>
            <div>
              {formatDateAtTimeFromUnix(data.date_created * 1000)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
