import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Actions } from 'actions/recordingActions';
import Recording from 'state/Recording';

const recordingReducer =
  (state = new Recording(), action: PayloadAction<Actions, string>) => {
    switch (action.type) {
      case Actions.FETCH_RECORDING_START:
        return state.set('error', null);
      case Actions.FETCH_RECORDING_SUCCESS:
        return state.set('error', null).set('data', action.payload['data']);
      case Actions.FETCH_RECORDING_FAIL:
      case Actions.FETCH_RECORDING_INVALID_URL:
        return state.set('error', action.payload['error']);
      default:
        return state;
    }
  };

export default recordingReducer;
