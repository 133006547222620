import React from 'react';
import MainLayoutContainer from './containers/MainLayoutContainer';
import { init as initMixpanel, track } from './utils/analytics';
import { withIntl } from './withIntl';
import { compose } from 'redux';

initMixpanel();

track('Public Recordings - Landed on Page.');

const App = () => {
  return (
    <MainLayoutContainer />
  );
}

export default compose(
  withIntl,
)(App);