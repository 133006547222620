import React from 'react';
import { ScaleLoader } from 'react-spinners';
import styles from './IFramePlaceholder.module.scss';

export const IFramePlaceHolder = () => {
  return (
    <div className={styles.iframePlaceholder}>
      <ScaleLoader/>
    </div>
  )
};
