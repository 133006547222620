import Icon from 'components/Common/Icon';
import LanguageDropdown from 'containers/LanguageDropdownContainer';
import React from 'react';
import classes from './Header.module.scss';

interface Props {
  countryCode: string
}

const Header: React.FunctionComponent<Props> = (props) => {

  const { countryCode } = props;

  return (
    <header className={classes.container}>
      <Icon classes={['icon-lsLogo', classes.logo]}/>
      <div className={classes.rightSide}>
        <a href={`https://www.lifesize.com/${(countryCode === 'zh') ? 'zh-Hans' : countryCode}/app-help`}
           rel="noopener noreferrer" target="_blank">
          <Icon classes={['icon-walkme']}/>
        </a>
        <LanguageDropdown/>
      </div>
    </header>
  );
};

export default Header;
