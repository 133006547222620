import { combineReducers } from 'redux-immutable';
import languageProviderReducer from './reducers/languageReducer';
import recordingReducer from './reducers/recordingReducer';

export default function createReducer() {
  return combineReducers({
    language: languageProviderReducer,
    recording: recordingReducer
  });
}
