import { connect } from 'react-redux';
import LanguageDropdown from '../components/LanguageDropdown/LanguageDropdown';
import { SupportedLanguage } from '../constants/languageConstants';
import { selectCurrentLocale } from '../selectors/languageSelector';
import { RootState } from '../state/Root';

const mapStateToProps = (state: RootState) => {
  const languageProvider: Array<string> = [];
  languageProvider[SupportedLanguage.cs] = "Čeština";
  languageProvider[SupportedLanguage.de] = "Deutsch";
  languageProvider[SupportedLanguage.en] = "English";
  languageProvider[SupportedLanguage.es] = "Español";
  languageProvider[SupportedLanguage.fr] = "Français";
  languageProvider[SupportedLanguage.it] = "Italiano";
  languageProvider[SupportedLanguage.nl] = "Nederlands";
  languageProvider[SupportedLanguage.no] = "Norsk";
  languageProvider[SupportedLanguage.pl] = "Polski";
  languageProvider[SupportedLanguage.pt] = "Português-Brasil";
  languageProvider[SupportedLanguage.fi] = "Suomi";
  languageProvider[SupportedLanguage.sv] = "Svenska";
  languageProvider[SupportedLanguage.zh] = "简体中文";
  languageProvider[SupportedLanguage.ja] = "日本語";
  languageProvider[SupportedLanguage.ko] = "한국어";

  return {countryCode: selectCurrentLocale(state), languageProvider};
};

export default connect(mapStateToProps)(LanguageDropdown);
