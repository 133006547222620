import { createStore, Store } from 'redux';
import combineMiddleware from './middleware';
import createReducer from './reducers';
import { Root, RootState } from './state/Root';

export default function configureStore(): Store<RootState> {
  return createStore(
    createReducer(),
    new Root(),
    combineMiddleware(),
  ) as Store<RootState>;
}
