import React from 'react';
import intl from 'react-intl-universal';
import { RootState } from './state/Root';
import { compose, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import { selectCurrentLocale } from './selectors/languageSelector';

const locales = {
  'cs': require('@lifesize/strings/recordings.public/cs.json'),
  'de': require('@lifesize/strings/recordings.public/de.json'),
  'en': require('@lifesize/strings/recordings.public/en.json'),
  'es': require('@lifesize/strings/recordings.public/es.json'),
  'fi': require('@lifesize/strings/recordings.public/fi.json'),
  'fr': require('@lifesize/strings/recordings.public/fr.json'),
  'it': require('@lifesize/strings/recordings.public/it.json'),
  'ja': require('@lifesize/strings/recordings.public/ja.json'),
  'ko': require('@lifesize/strings/recordings.public/ko.json'),
  'nl': require('@lifesize/strings/recordings.public/nl.json'),
  'no': require('@lifesize/strings/recordings.public/no.json'),
  'pl': require('@lifesize/strings/recordings.public/pl.json'),
  'pt': require('@lifesize/strings/recordings.public/pt.json'),
  'sv': require('@lifesize/strings/recordings.public/sv.json'),
  'zh': require('@lifesize/strings/recordings.public/zh.json'),
};

const localeDataUrl = process.env.REACT_APP_LOCALE_DATA;

const commonLocaleDataUrls = {
  'cs': `${localeDataUrl}en.js`,
  'de': `${localeDataUrl}de.js`,
  'en': `${localeDataUrl}en.js`,
  'es': `${localeDataUrl}es.js`,
  'fi': `${localeDataUrl}en.js`,
  'fr': `${localeDataUrl}fr.js`,
  'it': `${localeDataUrl}it.js`,
  'ja': `${localeDataUrl}ja.js`,
  'ko': `${localeDataUrl}ko.js`,
  'nl': `${localeDataUrl}nl.js`,
  'no': `${localeDataUrl}en.js`,
  'pl': `${localeDataUrl}pl.js`,
  'pt': `${localeDataUrl}pt.js`,
  'sv': `${localeDataUrl}sv.js`,
  'zh': `${localeDataUrl}zh.js`,
};

const mapStateToProps = (state: RootState, ownProps: {}) => {
  return {
    locale: selectCurrentLocale(state),
  };
};

interface Props {
  locale: string;
  initDone: boolean;
}

export const withIntlBase = (BaseComponent: React.ComponentType<{}>) => {

  return class extends React.Component<Props> {
    readonly state = { initDone: false };

    async componentDidMount() {
      await intl.init({
        currentLocale: this.props.locale || 'en',
        commonLocaleDataUrls: commonLocaleDataUrls,
        locales,
      });
      this.setState({ initDone: true });
    }

    async componentDidUpdate(prevProps: Props) {
      if (prevProps.locale && (this.props.locale !== prevProps.locale)) {
        // Reload site to re-initialize internationalization library
        window.location.reload();
      }
    }

    render() {
      const { initDone } = this.state;
      return (
        <React.Fragment>
          {initDone && <BaseComponent {...this.props} />}
        </React.Fragment>
      );
    }
  };
};

export const withIntl = compose(
  connect(mapStateToProps),
  withIntlBase,
);

export const withIntlTest = compose(
  defaultProps({ locale: 'en' }),
  withIntlBase,
);
