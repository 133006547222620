import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum Actions {
  FETCH_RECORDING_START = 'FETCH_RECORDING_START',
  FETCH_RECORDING_SUCCESS = 'FETCH_RECORDING_SUCCESS',
  FETCH_RECORDING_FAIL = 'FETCH_RECORDING_FAIL',
  FETCH_RECORDING_INVALID_URL = 'FETCH_RECORDING_INVALID_URL'
}

export const actions = {
  fetchRecording: (id: string, token: string) =>
    createAction(Actions.FETCH_RECORDING_START, { id, token }),
  fetchRecordingSuccess: (data: object) =>
    createAction(Actions.FETCH_RECORDING_SUCCESS, data),
  fetchRecordingFailure: (error: object) =>
    createAction(Actions.FETCH_RECORDING_FAIL, error),
  fetchRecordingInvalidUrl: (error: object) =>
    createAction(Actions.FETCH_RECORDING_INVALID_URL, error),
};

export default actions;
